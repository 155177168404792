@media (max-width: $tamanioMobile) {
    .content-planes {
        min-height: 659px !important;
    }
}

.content-planes {
    position: relative;
    background-color: #FAFAFA;
    border: solid 1px #E6E6E6;
    padding: 10px;
    text-align: center;
    min-height: 610px;
    margin-bottom: 10px;

    .secciones {
        margin-bottom: 15px;
        line-height: 26px;
        padding: 5px;
    }

    h3 {
        font-weight: bold !important;
        color: $colorAzul !important;
    }

    .content-title-plan {
        color: $colorAzul !important;
        font-size: 22px;
        font-weight: 700 !important;
        padding-top: 10px;
        font-size: 1.1em !important;
        text-transform: uppercase;

    }

    .plan-title-top {
        color: $colorAzul !important;
        font-size: 1.2em;
        font-weight: bold !important;
        text-transform: uppercase;
    }

    .content-title-plan2 {
        position: absolute;
        color: $colorAzul !important;
        font-size: 22px;
        font-weight: bold;
        text-transform: uppercase;
        margin-top: 30px !important;
        bottom: 110px;
        border-top: solid #ddd 1px;
        border-bottom: solid #ddd 1px;
        width: 100%;
        left: 0;
        font-size: 0.9em;
        padding: 3px 0px;

        .value {
            padding-left: 5px;
            font-size: 1.3em;
            font-weight: bold;
        }
    }

    p {
        margin: 5px 0;
    }

    .button {
        position: absolute;
        color: $colorBlanco !important;
        padding: 8px 0px;
        border-radius: 4px;
        text-transform: uppercase;
        width: 200px;
        bottom: 55px;
        margin: auto;
        transform: translateX(-50%);
    }

    .cotizar-extra {
        position: absolute;
        bottom: 5px;
        margin: auto;
        transform: translateX(-50%);
        width: 100%;
        font-size: 16px;
        color: #627171;
    }
}

.btn-busqueda {
    background-color: #94C01F !important;
    border: solid 1px #f1f1f1 !important;
    border-radius: 4px;
    padding: 6px 80px !important;
    color: $colorBlanco;
}

.btn-busqueda-sepultura {
    margin-top: 24px;
    text-align: center;
    padding: 8px 0px;
    background-color: #94C01F;
    border: none;
    border-radius: 4px;
    color: white;
    text-transform: uppercase;
    font-size: 0.8em;
    cursor: pointer;

    .btn-buscar-text {
        display: inline-block;
        color: $colorBlanco;
        font-weight: 700 !important;
        font-size: 14px;
        padding-left: 10px;
        font-family: 'Lato', sans-serif !important;
    }
}