tfoot {
    td {
        color: #627171;
    }
}

.content-contratos {
    box-shadow: 0px 0px 5px #ccc;
    padding: 40px;
    margin: 60px auto;

    .text-introduccion {
        p {
            margin-bottom: 15px;
        }

        h4 {
            margin: 0;
            font-weight: 700 !important;
        }
    }

    .error {
        border: solid 1px #ff0000;
    }

    &__detalle {
        .cabecera {
            background-color: #627171;
            padding: 25px;
            font-size: 18px;
            color: #fff;
            margin: 30px 0;
            cursor: pointer;
            position: relative;

            .btn {
                position: absolute;
                top: 16px;
                right: 5px;

                img {
                    width: 25px;
                    transform: rotate(0deg);
                }
            }
        }
    }

    .open-collapse {
        .icon-action-accordeon {
            transform: rotate(45deg) !important;
        }
    }

    .text-content {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: solid 1px #f1f1f1;
    }

    .row-content-cabecera {
        padding-bottom: 50px;
        margin-bottom: 50px;
        border-bottom: solid 1px #f1f1f1;

        .logo-sendero {
            width: 100%;
        }

        .content-redes {
            margin-top: 35px;

            p {
                vertical-align: middle !important;
                align-self: center;
                margin: 0;
                color: #00A5C8;
                font-weight: bold !important;
                font-size: 1.4em;
                margin-right: 10px;
                display: inline-block;
            }

            .content-icons {
                display: inline-block;

                a {
                    color: #007bff;
                    text-decoration: none;
                    background-color: transparent;
                }

                img {
                    margin: 5px;

                    &:hover {
                        -webkit-transition: all .15s ease;
                        transition: all .15s ease;
                        -webkit-transform: translateY(-5px);
                        transform: translateY(-5px);
                    }
                }
            }
        }
    }

    &__productos {
        h2 {
            text-align: center;
            margin: 30px 0 10px 0;
            font-size: 22px;
            font-weight: bold !important;
        }

        input[type=checkbox] {
            zoom: 2;
        }
    }

    .total {
        background-color: #F2F2F2;
        color: #627171;
        padding: 30px;
        margin: 20px 0;
        font-weight: 400 !important;
        font-size: 26px;
        text-align: center;
    }

    .checkout {
        p {
            margin: 10px 0 !important;
        }

        .email {
            margin: 10px 0 !important;
            background-color: #f1f1f1;
            ;
        }
    }

    .contact {
        span {
            font-size: 12px;
            font-weight: 300 !important;
        }

        .content_pat {
            border: 1px #95C11F solid;
            padding: 60px;
            background-color: #f2f2f2;
            max-height: 300px;

            h2 {
                color: #627171;
                text-align: center;
                font-size: 3em;
            }

            .content_img {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                margin: 20px;
                height: 80px;

                img {
                    width: 60px;
                    -o-object-fit: contain;
                    object-fit: contain;
                }
            }

            p {
                font-weight: 500 !important;
                font-size: 15px;
            }
        }

        .content_info {
            padding: 15% 10% 10% 10%;

            .tl {
                font-weight: 300;
                margin: 10px 0;
            }

            h5 {
                color: #627171;
                font-weight: bold !important;
            }

            img {
                max-width: 60%;
            }
        }
    }

    .btn-verde {
        margin: 10px 0;
    }
}

.content-login-popup {
    .btn-verde {
        width: 100%;
    }

    .car {
        margin: 80px auto;
        padding: 80px 100px;
        box-shadow: 0px 0px 10px #ccc;
    }
}

.modal-dialog {
    .content-login-popup {
        padding-bottom: 0 !important;

        .car {
            margin: 0 !important;
            padding: 0 50px !important;
        }
    }

    .volver {
        display: none;
    }
}

@media all and (max-width: 678px) {
    .content-login-popup {
        img {
            width: 100% !important;
            padding: 0 40px 50px 30px !important;
        }

        .car {
            margin: 40px auto !important;
            padding: 50px 20px 70px !important;
            box-shadow: none !important;
            border: none !important;
        }
    }

    .content-contratos {
        margin: 20px 0 !important;
        padding: 0;
        box-shadow: none;

        .table td,
        .table th {
            font-size: 12px;
        }

        .table {
            .head {
                td {
                    border: solid 1px #f1f1f1;
                }
            }
        }

        .cabecera {
            font-size: 14px;
        }

        .text-content {
            font-size: 14px;
        }
    }

    .center-mobile {
        text-align: center !important;
    }

    .modal-dialog {
        .content-login-popup {
            padding: 30px;

            .car {
                margin: 0 !important;
                padding: 80px 100px !important;
            }
        }
    }
}

.spinner {
    margin: 30px 0;
}

.mnt-opcion {
    input {
        zoom: 2;
    }

    .pantalla {
        position: relative;

        &.verifica {
            display: none;

            .bloque-pago {
                position: absolute;


                &.inactivo {
                    background-color: #d4d4d473;
                    border: solid 1px #dcdcdc;
                    width: 100%;
                    height: 100%;
                    display: none !important;
                }
            }
        }
    }
}
.sl-method-pay{
    h4{
        margin: 30px 0 15px;
        font-weight: 400 !important;
    }
    img{
        width: 110px !important;
        height: auto !important;
    }
    p{
        font-size: 18px;
    }
}

.table-detalle {
    width: 100%;
    margin: 20px 0;

    th {
        text-align: inherit;
        background-color: #811e6c !important;
        color: #ffffff;
        text-align: center;
        padding: 10px 0;
    }

    td {
        text-align: center;
    }
}
.btn-verde {
    background-color: #811e6c !important;
    color: #ffffff;
}

.row-content-cabecera {
    
    margin-bottom: 10px !important;
    strong {
        font-weight: 700;
        font-size: 16px !important;
        color: #811e6c !important;
    }
}
.content-redes {
    p {
        color: #811e6c !important;
    }
}