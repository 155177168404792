.top-mobile {
  #page-wrap {
    text-align: center;
    overflow: auto;
    width: 100%;
    padding: 0 0 12px 0;
  }

  .bm-menu-wrap {
    z-index: 999;
    top: 0 !important;

    * {
      outline: none !important;
    }
  }

  #Sidebar {
    height: 80.25px;
    position: fixed;
    width: 100%;
    background-color: #ffffff;
    top: 26px;
    z-index: 999;
    border-bottom: solid 1px #f1f1f1;

    .contac-lateral-mobile {
      color: $colorBlanco;
      font-size: 11px;
    }

    .img-logo-white {
      width: 170px !important;
    }

    .img-icons {
      width: 40px !important;
      height: 40px !important
    }

    .phone-lateral-mobile {
      font-size: 22px;
      font-weight: bold;
      color: $colorBlanco;
    }

    .registro {
      color: $colorBlanco;
    }
  }

  .bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    color: #d1d1d1;
    transition: color 0.2s;
  }

  .bm-item:hover {
    color: white;
  }

  .bm-burger-button {
    position: relative;
    width: 32px;
    height: 18px;
    left: 7%;
    top: 31px;
  }

  .bm-burger-bars {
    background: $colorAzul;
  }

  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  .bm-cross {
    background: #bdc3c7;
  }

  .bm-menu {
    background: $colorAzul;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }

  .bm-morph-shape {
    fill: #373a47;
  }

  .bm-item-list {
    color: #b8b7ad;
  }

  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }


  .bm-item.menu-mobile {
    margin: 0;
  }

  .menu-content-link {
    margin-bottom: 20px;
  }

  span.bm-burger-bars {
    height: 3.5px !important;
    border-radius: 5px;
  }

  span.bm-cross {
    width: 4px !important;
    height: 30px !important;
    background-color: $colorBlanco;
  }

  .arrow-black {
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    float: right;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 5px;

    &.right {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }

    &.left {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }

    &.up {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }

    &.down {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }

  .menu-content-link {

    & .collapse,
    .collapsing {
      & a {
        margin: 0px 0px 0px 10px;
        border-style: dotted none none none;
        padding: 6px 0px;
        font-size: 12px;

        &:before {
          content: '- ';
        }
      }

    }
  }

  .nav-item {
    list-style: none !important;

    a {
      display: block;
      color: $colorBlanco;
      padding: 10px 0 10px;
      margin: 0;
      font-size: 14px;
      border-top: solid 1px #f1f1f1;
      text-align: left;
      cursor: pointer;
      text-transform: uppercase;
    }
  }

  .menu-left {
    display: none;
    .bm-burger-button {
      left: 7%;
      top: 31px;
      float: left;
    }
  }
}