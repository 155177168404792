.box-top-text {
    margin-bottom: 10px;

    &__title {
        font-weight: bold !important;
        font-size: 20px !important;
        margin-bottom: 0 !important;
    }

    &__subtitle {
        margin: 0;
    }
}