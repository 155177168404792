.text-list-sacra {
    background-color: #811e6c !important;
    color: #fff; 
    opacity: .5;
}
.open-list-sacra {
    background-color: #811e6c !important;
    color: #fff;
    opacity: 1;
}
 ul {
    margin-left: 25px;
}
.selected {
    background-color: hotpink !important;
    color: #fff; 
    // opacity: .5;
}
#table-blue > thead {
    background-color: #811e6c !important;
    color: #fff;
    opacity: 1;
}