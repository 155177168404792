body {
    font-family: 'Lato', sans-serif !important;
}

.lato-light {
    font-weight: 300 !important;
    font-style: normal !important;
}

.lato-black {
    font-weight: 900 !important;
    font-style: normal !important;
}

a:hover {
    text-decoration: none;
}

p {
    font-size: 16px;
    margin: 0;
    color: #627171;
    font-weight: 300 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 300 !important;
    letter-spacing: 1px;
    color: #627171;
}

h2 {
    text-transform: uppercase;
    font-size: 28px;
}
.title-flotante-banner {
    font-size: 38px !important;
}



.punto1,
.punto3 {

    .circle {
        background-color: #95C11F;
    }

}

.punto2 {

    .circle {
        background-color: #23A7CB;
    }

}

.box-punto {
    margin: 40px 0;

    .content-img {
        .circle {
            border-radius: 60px;
            display: inline-block;

            img {
                padding: 15px;
                width: 100px;

            }

            &.orange {
                background-color: #f39200;
            }

            &.blue {
                background-color: #23a7cb;
            }

            &.green {
                background-color: #96bf0c;
            }

            &.morado {
                background-color: #811e6c;
            }

            &.negro {
                background-color: rgba(0,0,0,1);
            }

        }
    }

    .title-box {
        font-weight: bold !important;
        font-size: 18px;
        margin: 15px 0 10px 0;
    }
}

.content-image {

    .logo {
        position: absolute;
        bottom: 15px;
        width: 60px;

        &.order1 {
            right: -3%;
        }

        &.order2 {
            left: -3%;
        }
    }

    .box-image {
        img {
            width: 150px !important;
            margin-bottom: 20px;
        }

        h1 {
            font-size: 42px;
            font-weight: bold !important;
        }

        p {
            margin-bottom: 10px;
        }
    }
}

.main-mantencion {
    h1 {
        font-weight: bold !important;
    }

    p {
        margin-bottom: 10px;
    }

    .box-mantencion {
        margin: 30px 0;

        .content-img {
            vertical-align: middle;
            display: inline-flex;
            margin-bottom: 10px;
            max-height: 110px;

            img {
                width: 100%;
                display: inline-block;
            }

            p {
                display: inline-block;
                color: #627171 !important;
                font-size: 72px;
                font-weight: bold !important;
            }
        }

        .title-box {
            font-size: 18px;
            font-weight: bold !important;
            margin-bottom: 10px;
        }
    }
}

.main-mapa {
    h1 {
        font-weight: bold !important;
        text-transform: uppercase;
        margin: 20px 0;
    }

    .form-control {
        display: inline-block !important;
        width: 30%;
    }

    .img-parque-mantencion {
        width: 100%;
    }



    @media (max-width: 790px) {
        .form-control {
            width: 100%;
        }
    }
}

.collapse-info {
    .col-collapse {

        .collap {
            text-align: left;
            width: 100%;
            background-color: #00a5c8;
            opacity: 1;
            margin-top: 5px;
            padding: 8px 10px;
            color: #fff;
            font-weight: 100 !important;
            font-size: 16px;

            & .open {
                .icon-list {
                    transform: rotate(0deg);
                }
            }

            &.close {
                .icon-list {
                    transform: rotate(180deg);
                }
            }
        }

        .content {
            padding: 10px;
            border: solid 1px #ccc;
            margin-bottom: 5px;
        }
    }
}


.logo {
    padding: 0;

    img {
        width: 100% !important;
        max-width: 180px;
        padding: 0 10px;
        margin-top: -8px;
    } 
}

.row-form {
    .form-control {
        margin-bottom: 10px;
    }
}
.modal-header {
    border: none;
    padding: 5px 1rem 0;
}
.content-login-popup {
    padding-bottom: 30px;

    & .content-logo { 
        img {
            width: 250px;
            padding: 50px 0;
        }
    }

    & .btn-popup-pago-rapido {
        width: 100%;
    }
}
 