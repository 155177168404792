.content-planes {
    padding: 10px 8px !important;

    &.crematorio {
        min-height: 550px !important;
    }

    h4 {
        color: $colorAzul !important;
        min-height: 55px;
    }

    .planes-detalle {
        min-height: 230px;
    }
}

.parque-crematorio {

    &__info {
        max-width: 1000px !important;
        margin: auto;
    }

    .paque-crematorio-planes {
        .content-planes {
            border-radius: 4px;
            min-height: 320px !important;

            hr {
                display: none;
            }

            .content-title-plan {
                border-top: solid 1px #DDD;
                border-bottom: solid 1px #DDD;
                padding: 6px;
            }

            h4 {
                text-transform: uppercase;
                font-size: 1.1em;
                font-weight: bold !important;
                padding-bottom: 10px;
                border-bottom: solid 1px #DDD;
            }
        }
    }
}

.button {
    cursor: pointer;

    &:hover {
        background-color: #1aa5c6;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
}

.react-multi-carousel-list.carousel-container.undefined {
    padding-bottom: 40px;
}