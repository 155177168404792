.detail-contrato{
    h2{
        font-size: 20px;
        font-weight: 500 !important;
    }
    .box{
        min-height: 130px;
        h3{
            font-size: 16px !important;
            font-weight: bold !important;
        }
        p{
            font-size: 14px;
        }
    }
}