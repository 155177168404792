.funeral-del-dia{
    width: 100%;
    margin: auto;
    margin-bottom: 100px !important;
    .header-table{
        border: solid $colorAzul 10px;
    }

    th {
        background-color: $colorAzul;
        color: $colorBlanco;
        text-align: center !important;
        border: solid 0.1px $colorBlanco;
        font-size: 0.75em;
        padding: 8px !important;
    }

    td{
        color: #666;
        font-size: 0.75em;
        padding: 20px 5px !important;
    }

    &__name:hover, &__hora:hover, &__parque:hover{
        cursor: pointer;

        .funeral-del-dia__icon-sort{
            color: black;
        }
    
    }

    &__icon-sort{
        float: right;
        transform: translateY(3px);
    }
}

.container-funerales-dia{
    .info{
        text-align: center;
        padding: 0 0px 30px 0px;
    }
}

.grilla-loading{
    text-align: center;
    padding-bottom: 20px;
}