@import './../../assets/scss/color.scss';

.boxNotifications {
    border: solid 1px #f9f9f9;
    margin-bottom: 10px;
    padding: 10px 0;

    .text {
        text-align: left;
        margin-bottom: 8px;
    }

    .text_description {
        margin: 18px 0;
        line-height: initial;
        font-weight: 500 !important;
    }

    .content_blue {
        background-color: $colorSimpleBlue;
        padding: 10px 20px;
        border-radius: 15px;

        p {
            color: $colorBlue !important;
            font-weight: bold !important;
            line-height: initial;
        }

        .pago {
            border-radius: 20px;
            width: 100%;
            background-color: $colorBlue;
            border: none;
            color: $colorWhite !important;
            padding: 2px 0;
            display: inline-block;
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;
            text-align: center;

            &:hover {
                background-color: $colorGreen;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            }
        }
    }

    .pago {
        border-radius: 20px;
        width: 100%;
        background-color: $colorGreen;
        border: none;
        color: $colorWhite !important;
        padding: 6px 0;
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        text-align: center;

        &:hover {
            background-color: $colorBlue;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
    }

    .ver {
        background-color: $colorBlue;
        color: $colorWhite !important;
        cursor: pointer;
        padding: 5px 30px;
        border-radius: 15px;

        &:hover {
            background-color: $colorGreen !important;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
    }
}