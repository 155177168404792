.ubicacion-sepulturas{
    &__info{
        max-width: 80%;
        margin: auto;
        text-align: center;
        margin-top: 100px;
        padding-bottom: 20px;

    }

    .content-buscador{
        background-color: #FAFAFA;
        padding: 105px 0 40px;
        margin: 30px 0;
    }

    &__inputs{
        margin-bottom: 20px;
    }

    .button-busqueda-ubic{
        padding: 10px 20px;
        text-transform: uppercase;
        font-size: 0.8em;
        width: 100% !important;
        border: none !important;
        border-radius: 2px !important;
        margin: 20px 0 0 !important;
        border-radius: 4px !important;
    }
}


.table-sepulturas{
    width: 100%;
    margin: auto;
    margin-bottom: 100px !important;
    margin-top: 48px;
    .header-table{
        border: solid #95c11f 10px;
    }

    th {
        background-color: #95c11f;
        color: $colorBlanco;
        text-align: center !important;
        border: solid 0.1px $colorBlanco;
        font-size: 0.75em;
        padding: 8px !important;
    }

    td{
        color: #666;
        font-size: 0.75em;
        padding: 20px 5px !important;
    }
}

.icon-ubicacion:hover{
    filter: hue-rotate(250deg);
}

.icon-ubicacion{
    width: 20px;
}

.table-busqueda{
    tr{
        td{
            font-size: 16px;
            font-weight: 300 !important;
        }
    }
}