.content-parque-para-todos {
    .parrafo {
        font-size: 21px;
        margin: 0 !important;
    }

    .middle {
        padding: 20% 0;
    }

    .home-featured {
        margin: 50px auto 70px;
    }

    .action {
        cursor: pointer;
        border-bottom: solid 1px;
        display: inline-block;
        font-weight: 500 !important;

        &.orange {
            color: #f39200;
        }

        &.blue {
            color: #23a7cb;
        }

        &.green {
            color: #96bf0c;
        }
    }

    .title-comunidad {
        margin: 30px 0 40px;
    }

    .logo-section {
        .line {
            width: 40px;
            height: 2px;
            background-color: #96bf0c;
            display: inline-block;
            margin-right: 10px;
            vertical-align: middle;
            margin-top: -6px;
        }

        img {
            display: inline-block;
        }
    }

    .title-mantencion {
        margin-bottom: 30px;
    }

    .icon-flotante {
        position: absolute;
        right: -58px;
        top: 180px;
        z-index: 99;

        img {
            width: 90px !important;
        }
    }
}