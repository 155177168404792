@import './../../assets/scss/color.scss';

.container-cartola {
    .box-checkbox {
        @media (min-width: 768px) {
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
            padding: 15px 15px 10px !important;
        }

        border-radius: 10px;
        display: inline-block;

        input {
            zoom: 2;
        }
    }

    .title-col {
        margin-bottom: -5px;

        &.blue {
            color: $colorBlue;
            font-weight: bold !important;
        }

        &.green {
            color: $colorGreen;
            font-weight: bold !important;
        }

        &.gray {
            color: $colorGray;
            font-weight: bold !important;
        }
    }
}
.txt-acuerdos_pago_pie {
    opacity: .75;
}