@import './../../assets/scss/color.scss';

.menu-horizontal {
    width: 100%;
    .menu-items {
        padding: 5px 0;

        li {
            list-style: none;

            a {
                width: 100%;
                display: inline-block;
                text-align: left;
                padding: 15px 48px 15px 0 !important;
                font-size: 14px;
                text-transform: none;
                color: $colorBlack;
                letter-spacing: 1px;
            }
        }
    }
}