.text-verde {
    color: #95c11f !important;
    text-decoration: underline;
    margin-bottom: 10px;

    a {
        color: #95c11f !important;
    }
}

.contratos-page {
    .content-data {
        padding: 0px 80px;
    }

    h2 {
        font-size: 1.5em;
        font-weight: 500 !important;
    }

    p {
        padding-bottom: 20px;
    }

    a {
        color: #95c11f;
        text-decoration: underline;
    }
}

.reglamentos-container {
    max-width: 1000px;
    margin: auto;

    p {
        padding: 20px 0;
        border-bottom: solid 1px #ddd;
        font-size: 1.2em;
    }

    .botones-reglamentos {
        padding: 10px;
        padding-bottom: 75px;

        .btn-descargar {
            background-color: #FAFAFA;
            margin: 5px 0;
            padding: 10px 15px;
            color: #627171;
            border: solid 1px #cccccc;
            text-align: left;
            font-weight: 0;

            a {
                color: #627171 !important;
            }

            svg {
                width: 16px;
                height: 16px;
                float: right;
                margin-top: 5px;
                fill: #627171;
            }
        }

        .btn-descargar:hover {
            background-color: $colorVerde;
            border: none;
            color: $colorBlanco !important;
            text-decoration: none !important;
            cursor: pointer;

            a {
                color: $colorBlanco !important;
                text-decoration: none !important;
            }

            svg {
                fill: white;
            }

        }

        &__item {
            padding: 0 5px;
        }
    }
}

.img-contratos {
    width: auto;
    height: 540px;
}