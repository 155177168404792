.bgsendero {

    display: inline-block;
    padding: 0.75em 0.75em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 25px;
    width: 120px;

    &-success{
        color: #34BA70;
        background-color: #BCF2B1;
        margin-left: -35px;
    }
    &-error{
        color: #DB5A5F;
        background-color: #FFBFC2;
    }
}

.table-detalle {
    width: 100%;
    margin: 20px 0;

    th {
        text-align: inherit;
        // background-color: #00a5c8!important;
        // background-color: #fff !important;
        color: #ffffff;
        text-align: center;
        padding: 10px 0;
    }

    td {
        text-align: center;
    }
}

.text-title-detalle-pago {
    color: #627171 !important;
    font-weight: 700 !important;
}

.text-subtitle-detalle-pago {
    color: #859090 !important;
    font-weight: 600;
    span {

        color: #677575 !important;
        font-weight: 700;
        text-transform: uppercase;
    }
}

.label-sendero-error {
    color: #DB5A5F;
    text-decoration: underline;
    margin-left: 10px;
    cursor: pointer;
}

.table-detalle {
    th {
        background-color: #fff;
    }
    thead tr th {
        background-color: #02a6c8;
        
        &:nth-child(1){
            border-radius: 15px 0px 0px  15px;
        }

        &:nth-last-child(1) {
            border-radius: 0px 15px 15px  0px;
        }
    }

    tbody tr {
        border-bottom: 1px solid #E5E5E5;
        height: 50px;
    }

}

.card-detalle-pago {
    background-color: #FFECED !important;
    border-color: #FFEDEE !important;
    border-radius: 15px !important;
    color: #201E1F !important;
    
    &__caracteristicas {
        text-align: left;
        margin-bottom: 0px !important;
        margin-left: -15px !important;
        
        li 
        {
            list-style: none;
            font-weight: 600;
            &:nth-child(1) {
                font-weight: 900;
            }
        }
    }
    &__imagen {
        height: 45px;
    }
}
.clsViewExterno {
    background-color: #fff !important;
}