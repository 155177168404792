@import './../../assets/scss/color.scss';

.v-box {
    border-radius: 20px;
    padding: 10px 30px;
    margin-top: 10px;
    margin-bottom: 20px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.02), 0 2px 10px 0 rgba(0, 0, 0, 0.1);

    &__detail {
        margin-bottom: 20px;

        p {
            font-size: 14px;
        }

        h2 {
            font-size: 16px;
            font-weight: bold !important;
            margin: 0;
            text-transform: capitalize;
        }

        .blue {
            color: $colorBlue;
        }

        .green {
            color: $colorGreen;
        }

        .gray {
            color: $colorGray;
        }
    }

    ul {
        margin-left: 20px;

        li {
            font-weight: bold;
            &::marker {
                color: $colorGreen !important;
            }
        }
    }
}