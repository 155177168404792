@import './../../assets/scss/color.scss';

@media (max-width: 768px) {
    .sin-padding {
        padding: 0 !important;
    }
}

.box-agreement-detail {
    position: relative;

    @media (min-width: 768px) {
        img {
            border-top-left-radius: 28px;
            border-top-right-radius: 28px;
        }

        &__name {
            width: 100%;
            left: 0;
            top: 45px !important;
            text-align: center;

            h2 {
                font-size: 20px !important;
                margin-bottom: 0;
            }

            p {
                font-size: 16px !important;
            }
        }

        .container-description {
            border-radius: 0 !important;
            box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.03), 0 2px 15px 0 rgba(0, 0, 0, 0.03);
            padding-bottom: 5px;
            padding: 25px 40px !important;

            &__detail {
                .content {

                    h3 {
                        font-size: 16px !important;
                    }

                    p {
                        font-size: 14px;
                        margin-bottom: 0 !important;
                    }
                }
            }
        }
    }


    &__name {
        position: absolute;
        top: 60px;

        h2 {
            color: $colorWhite;
            font-weight: bold !important;
            font-size: 18px;
            text-transform: none !important;
        }

        p {
            color: $colorWhite;
            font-size: 14px;
        }
    }

    .container-description {
        border-top-left-radius: 28px;
        border-top-right-radius: 28px;
        margin-top: -20px;
        position: relative;
        background-color: #fff;
        padding-top: 40px;

        &__detail {
            .content {
                margin-bottom: 30px;

                h2,
                h3 {
                    color: $colorBlue;
                    font-weight: bold !important;
                    font-size: 18px;
                    text-transform: none !important;
                    margin-bottom: 2px !important;
                }

                .title-line {
                    width: 25px;
                    height: 4px;
                    background-color: $colorBlue;
                    margin-bottom: 15px;
                }

                a {
                    display: block;
                    width: 100%;
                    margin: 10px 0;
                    border-radius: 20px;
                    background-color: $colorBlue;
                    border: none;
                    color: $colorWhite;
                    padding: 8px 0;
                    font-size: 16px;
                    font-weight: 500;
                    text-transform: uppercase;
                    text-align: center;

                    &:hover {
                        background-color: $colorSimpleBlue;
                        color: $colorBlue;
                        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                    }
                }
            }
        }
    }
}